// Generated by Framer (d0e15c0)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Carousel from "https://framerusercontent.com/modules/UIrMjSS6ZX89L0CsT8k6/w90zR0qzeh1mgaDSvD54/Carousel.js";
const CarouselFonts = getFonts(Carousel);

const cycleOrder = ["l0lQ8EaHa"];

const serializationHash = "framer-AdEyz"

const variantClassNames = {l0lQ8EaHa: "framer-v-1q2kul8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "l0lQ8EaHa", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AdEyz", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1q2kul8", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"l0lQ8EaHa"} ref={ref ?? ref1} style={{backgroundColor: "rgba(153, 238, 255, 0)", ...style}}><motion.div className={"framer-1tzc3ps-container"} layoutDependency={layoutDependency} layoutId={"umofSs0O_-container"}><Carousel align={"center"} ariaLabel={""} arrowObject={{arrowFill: "rgba(0, 0, 0, 0.2)", arrowPadding: 20, arrowRadius: 40, arrowSize: 40, showMouseControls: true}} axis borderRadius={0} fadeObject={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeTransition: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: false}, fadeWidth: 25}} gap={0} height={"100%"} id={"umofSs0O_"} layoutId={"umofSs0O_"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} progressObject={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 4, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: false, showScrollbar: false}} sizingObject={{heightInset: 0, heightRows: 2, heightType: "auto", widthColumns: 4, widthInset: 0, widthType: "auto"}} slots={[]} snapObject={{fluid: false, snap: true, snapEdge: "end"}} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AdEyz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AdEyz .framer-ca2cok { display: block; }", ".framer-AdEyz .framer-1q2kul8 { align-content: start; align-items: start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: auto; padding: 0px 0px 0px 0px; position: relative; width: 1268px; }", ".framer-AdEyz .framer-1tzc3ps-container { flex: none; height: 212px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-AdEyz .framer-1q2kul8 { gap: 0px; } .framer-AdEyz .framer-1q2kul8 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-AdEyz .framer-1q2kul8 > :first-child { margin-top: 0px; } .framer-AdEyz .framer-1q2kul8 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 212
 * @framerIntrinsicWidth 1268
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerkgEf39CF3: React.ComponentType<Props> = withCSS(Component, css, "framer-AdEyz") as typeof Component;
export default FramerkgEf39CF3;

FramerkgEf39CF3.displayName = "Variant-fr";

FramerkgEf39CF3.defaultProps = {height: 212, width: 1268};

addFonts(FramerkgEf39CF3, [...CarouselFonts])